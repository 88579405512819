@font-face {
  font-family: 'Carrol';
  src: url('../media/fonts/carrol.otf');
}

body {
  margin: 0;
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  overflow-x: hidden;
  scroll-behavior: smooth;
  overflow-y: scroll;
}

div.all {
  background-color: #ecf2ff;
  position: absolute;
  top: 102px;
}

header {
  position: absolute;
  top: 0;
  left: 0;
  height: 100px;
  /* width: 100vw; */
  width: 100%;
  border-bottom: 4px solid #c4c5ce;
  display: flex;
  justify-content: space-between;
  background-color: #f5f8ff;
  z-index: 2;
}

header button.login {
  margin: auto 15px;
  padding: 15px;
  text-transform: uppercase;
  outline: none;
  border: none;
  font-size: 25px;
  font-weight: 600;
  color: #000d29;
  background: none;
  cursor: pointer;
  border-radius: 30px;

  transition: all 200ms ease-out;
}

header button.signup {
  margin: auto 15px;
  padding: 15px 30px;
  text-transform: uppercase;
  outline: none;
  border: none;
  font-size: 25px;
  font-weight: 600;
  border-radius: 30px;
  color: white;
  background: #f50031;
  transition: all 200ms ease-out;
  cursor: pointer;
}

header a.link {
  margin: auto 15px;
  padding: 15px;
  text-transform: uppercase;
  outline: none;
  border: none;
  font-size: 25px;
  font-weight: 600;
  color: #000d29;
  background: none;
  cursor: pointer;
  border-radius: 30px;
  transition: all 200ms ease-out;
  text-decoration: none;
}

header button.login:hover {
  color: #4782ff;
}

header button.signup:hover {
  box-shadow: 5px 5px 5px #ff99ad;
}
header > div.header-left {
  display: flex;
  align-items: center;
}

header div.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

header div.right {
  display: flex;
  align-items: center;
}

div.languages {
  display: flex;
  font-size: 20px;
  font-weight: 500;
  margin-left: 40px;
}

div.languages > div {
  text-align: center;
  width: 40px;
  padding: 10px;
  margin: 5px;
  color: #000d29;
  cursor: pointer;
  border-radius: 40px;
  transition: all 240ms;
}

div.logo > svg.logo {
  width: 50px;
  padding: 15px;
}

div.logo-text {
  display: block;
  font-size: 40px;
  font-weight: 400;
  color: #000654;
}

b.logo-bold {
  font-weight: 500;
}

.logoa,
.logob {
  fill: none;
  stroke: #000654;
  stroke-miterlimit: 10;
  stroke-width: 2.5px;
}

.logob {
  stroke-linecap: square;
}

.logoc {
  fill: #ff3860;
}

footer {
  /* margin-top: 100px; */
  height: 200px;
  width: 100vw;
  border-top: 2px solid #001a52;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #f5f8ff;
  z-index: 2;
}

footer a {
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #000;
  text-decoration: none;
}

footer div.top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

footer div.bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

footer div.bottom > img {
  padding: 10px;
  margin: 5px;
  width: 120px;
}

footer div.break {
  width: 90vw;
  margin: 0px auto;
  height: 1px;
  background-color: black;
}

.trademark {
  font-size: 12px;
}

@media only screen and (max-width: 850px) {
  header button.login {
    margin: auto 10px;
    padding: 10px 20px;
    font-size: 16px;
  }

  header button.signup {
    margin: auto 10px;
    padding: 10px 20px;
    font-size: 16px;
  }

  div.languages {
    font-size: 16px;
  }
}

@media only screen and (max-width: 700px) {
  header {
    height: 65px;
  }

  div.all {
    top: 65px;
  }

  header button.login {
    margin: auto 10px;
    padding: 10px 20px;
    font-size: 20px;
  }

  header button.signup {
    margin: auto 10px;
    padding: 10px 20px;
    font-size: 14px;
  }

  div.languages {
    margin-left: 2px;
    font-size: 14px;
    letter-spacing: 1px;
  }

  div.languages > div {
    text-align: center;
    width: 30px;
    padding: 8px;
    margin: 2px;
    color: #000d29;
    cursor: pointer;
    transition: all 240ms;
  }

  div.logo svg.logo {
    width: 30px;
  }

  div.logo-text {
    display: none;
  }

  footer div.bottom > img {
    padding: 3px;
    margin: 0px;
    width: 65px;
  }
}

/*
#techlab {
  width: 120px;
}
#gov1430 {
  width: 160px;
}
#mydatacan {
  width: 145px;
}
#dataprivacylab {
  width: 180px;
} */
