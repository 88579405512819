html {
  width: 100vw;
  height: 100vh;
  max-width: 100%;
}

body {
  width: 100%;
  height: 100%;
  background-color: #d8e6ed;
}

div.all {
  width: 100%;
}

main {
  /* width: 100vw; */
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  font-family: 'Carrol';
}

main h1 {
  font-weight: 300;
  font-size: 60px;
  /* padding: 40px 30vw 40px 40px; */
  margin: 0px;
  color: #000d29;
  margin-bottom: 32px;
}

main h2 {
  font-weight: 500;
  font-size: 4vw;
  margin: 0px;
  color: #000d29;
}

div.secure h2 {
  font-weight: 500;
  font-size: 40px;
  padding: 20px 20px 20px 30vw;
  margin: 0px;
  color: #000d29;
  text-align: right;
}

main div.secure {
  display: flex;
  width: 100vw;
}

main h3 {
  font-weight: 400;
  font-size: 24px;
  margin: 0px;
  color: #000d29;
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
}

main button.signup {
  margin: auto 15px;
  padding: 25px 40px;
  text-transform: uppercase;
  outline: none;
  border: none;
  font-size: 40px;
  font-weight: 600;
  border-radius: 70px;
  color: #f50031;
  background: none;
  border: 5px solid #f50031;
  transition: box-shadow 100ms ease-out;
}

main button.signup:hover {
  box-shadow: 5px 5px 5px #ff99ad;
}

.booth {
  width: 20vw;
  max-width: 200px;
  min-width: 100px;
  margin: 20px;
}

div.checks-anim {
  width: 100vw;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

video {
  border-radius: 30px;
}

.ch {
  width: 250px;
  height: 250px;
}

.one,
.three {
  position: relative;
}

.checks-img-double-one {
  position: relative;
  top: 0;
  left: 0;
}

.checks-img-double-two {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
}

.partner-button {
  text-decoration: none;
  background: #0897e9;
  padding: 24px;
  color: white;
  border-radius: 4px;
  display: inline-block;
  font-size: 1.35em;
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
  margin-top: 60px;
}

@media only screen and (max-width: 700px) {
  main button.signup {
    margin: auto 15px;
    padding: 20px 30px;
    text-transform: uppercase;
    outline: none;
    border: none;
    font-size: 30px;
    font-weight: 600;
    border-radius: 70px;
    color: #f50031;
    background: none;
    border: 5px solid #f50031;
    transition: box-shadow 100ms ease-out;
  }

  main h1 {
    font-weight: 500;
    font-size: 40px;
    padding: 20px 20px;
  }

  main h2 {
    font-size: 5.5vw;
    padding: 20px 60px 20px 20vw;
    margin: 0px;
    color: #000d29;
    /* text-align: right; */
  }

  div.secure h2 {
    font-size: 20px;
    padding: 20px 20px 20px 5vw;
  }

  main h3 {
    font-weight: 400;
    font-size: 15px;
    padding: 20px 10px;
    margin: 0px;
    color: #000d29;
  }
}