.state > path:focus {
  outline: 0;
}

.state > path {
  outline: 0;
  border: 0;
}

.state > path {
  outline: 0;
  transition: stroke-width 60ms cubic-bezier(0.165, 0.84, 0.44, 1),
    fill 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.state > path:hover {
  /* fill: orange; */
}

div.direction span {
  font-size: 50px;
  font-weight: 800;
}

div.map-flex {
  width: 100vw;
  max-width: 1000px;
  display: flex;
  margin: 0px auto;
  margin-bottom: 200px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

div.card-main {
  width: 400px;
  margin: 50px;
  display: flex;
  flex-direction: column;
}

div.direction {
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 30px;
  margin-bottom: 20px;
}

div.mapselect-select {
  width: 400px;
  margin: 0px auto;
}

div.map {
  display: flex;
}

svg.map-ne {
  flex: none;
}

div.card-input {
  width: 360px;
  min-height: 400px;
  background-color: #dde3f0;
  border-radius: 35px;
  padding: 25px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div.card-input h1 {
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 90px;
}

.label {
  color: #6f707a;
  display: flex;
  align-items: center;
}

.label2 {
  margin: 10px 0px 10px 0px;
}

.labelwrap {
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}

.label label,
.main-label {
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 26px;

  outline: none;
  white-space: nowrap;
  text-transform: uppercase;
  color: #6f707a;
}

.label input {
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 28px;
  border-radius: 8px;
  color: #000000;
  background: #ecf2ff;
  border: none;
  outline: none;
  margin: 0px 0px 0px 10px;
  padding: 2px 8px;
}

.date {
  width: 30px;
  margin: 0px 0px 0px 10px;
}

.label input::placeholder {
  color: #b7b7c3;
}

.map-labels {
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.label input.text {
  width: 25vw;
}
.label input.date {
  width: 40px;
  margin: 0px 0px 0px 5px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

div.inputs-head {
  display: flex;
  flex-direction: column;
}
div.inputs-head h1 {
  display: flex;
  flex-direction: column;
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  margin: 0px;
  color: #444444;
  text-transform: uppercase;
}

div.activate {
  width: 100vw;
  max-width: 940px;
  height: 70px;
  display: flex;
  /* background-color: #dde3f0; */
  border-radius: 20px;
  align-items: center;
  position: relative;
}

input.lookup {
  border: 5px solid #5a5b69;
  color: #5a5b69;
  text-align: center;
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 14px;
  height: 60px;
  margin: 5px;
  z-index: 2;
}

button.lookup {
  border: 5px solid #5a5b69;
  color: #5a5b69;
  text-align: center;
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 45px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 14px;
  height: 60px;
  margin: 5px;
  z-index: 2;
}

div.activate-desc {
  font-family: 'Gotham A', 'Gotham B';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding: 0px 30% 0px 10px;
  color: #1c1d22;
  z-index: 2;
}

div.card-animation {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  box-sizing: border-box;
  z-index: 1;
  background-color: #ff9c50;
}

h4 {
  color: #6f707a;
  font-family: 'Tungsten A', 'Tungsten B';
  font-weight: 500;
  font-size: 20px;
  margin: 40px 0px 10px 0px;
  padding: 0;
}

div.update {
  color: black;
  cursor: pointer;
  font-family: 'Tungsten A', 'Tungsten B';
  text-transform: lowercase;
  font-weight: 800;
  font-size: 20px;
}
