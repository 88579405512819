div.output-line {
  display: flex;
  margin: 5px 0px;
  justify-content: space-between;
  padding: 0px 20px;
}

div.output-text {
  font-size: 22px;
}

div.output-item {
  color: black;
  padding-left: 40px;
  text-align: right;
  white-space: normal;
}

div.no {
  height: calc(100% - 80px);
  width: calc(100% - 80px);
  text-align: center;
  font-size: 80px;
  padding: 40px;
  text-transform: uppercase;
  color: white;
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
}

.nav-lookup {
  font-family: 'Tungsten A', 'Tungsten B';
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  cursor: pointer;
  margin: 10px;
  text-align: center;
}

g.rsm-geographies {
  width: 800px;
}
svg.rsm-svg {
  width: 800px;
  height: 300px;
}
